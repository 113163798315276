.search-sorting-bar button.dropdown-toggle {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.search-sorting-bar button.btn {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem !important;
}

.search-sorting-bar button.btn:hover {
  color: #212529;
}
