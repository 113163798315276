:root {
    --default-font-size: 16px
}

html {
    /* default 1 rem = 16 px, 1:16 ratio */
    /* here if set font-size: 32px; 1 rem = 32 px */
    font-size: var(--default-font-size);
}

.pac-container 
/* for the auto complete to show on modal */
{z-index: 99999999999 !important;}  

.requirdInput {
     border: 1px solid red;  
}

.sideBarItem {
    font-size: 1.1rem !important;
    cursor: pointer;
}

.selectedSideBarItem {
    font-size: 1.1rem !important;
    background-color: #e9ecef !important;
    cursor: pointer;
}

.dateToForm {
    justify-content: flex-start;
}

.formMinusTop {
    margin-top: -20px;
}

.table-action{
    cursor: pointer;
}

.cursorPointer {
    cursor: pointer;
}

.btnCloseImg {
    color: grey;
    margin-top: -170px;
    margin-right: -40px;
    display: flex;
    justify-content: flex-end;
    z-index: 3 !important;
    font-size: 12px;
    cursor: pointer;
}

.imgClose {
    width: 220px;
    height: 180px;
}

.imgClose2 {
    width: 100px;
    height: 70px;
    margin-left: 20px;
}

.calendarStatus {
    display: flex;
}

.btnLangContainer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.btnLangWrapper {
    background-color: rgba(217, 217, 217);
    width: 80px;
    height: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.btnActive {
    background-color: rgba(98, 113, 221);
    width: 35px;
    height: 25px;
    border-radius: 15px;
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btnInActive {
    background-color: rgba(217, 217, 217);
    width: 35px;
    height: 25px;
    border-radius: 15px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mousePointer {
    cursor: pointer;
}

.tableTitle {
    font-size: 14px;
}

.tableRowTitleDouble {
    font-size: 3rem;
    white-space: normal;
    font-weight: 600;
}
.tableRowTitle {
    font-size: 1.5rem;
    white-space: normal;
    font-weight: 600;
}

.tableRowTitleGrey {
    font-size: 1.5rem;
    color: #a4a9af;
    white-space: normal;
    font-weight: 600;
}

.tableRowTitleBigger {
    font-size: 2rem;
    white-space: normal;
    font-weight: 600;
}
.tableRowSecondTitle {
    font-size: 1.2rem;
    white-space: normal;
    font-weight: 600;
}

.tableRowSubTitleMain {
    font-size: 1.2rem;
    color: #a4a9af;
    white-space: normal;
    font-weight: 600
}

.cardWhiteText {
    color: #ffffff !important;
}

.tableRowSubTitle {
    font-size: 1rem;
    color: #a4a9af;
    white-space: normal;
}

.searchBarText {
    font-size: 1rem;
}

.remarkTextArea {
    font-size: 1.1rem;
}

.tableRowSubSubTitle {
    font-size: 0.7rem;
    color: #a4a9af;
    white-space: normal;
}
.tableRowContent {
    font-size: 1rem;
    white-space: normal;
}

.tableRowRemark {
    font-size: 1rem;
    white-space: normal;
    color: #d11020;
}

.tableHiddenHeader {
    display: none;
}



.cardIconButton {
    font-size: 1.4rem;
}


.filterBadge {
    font-size: 1rem;
}

.statusBadge {
    font-size: 1rem;
    cursor: pointer;
}

.statusBadgeSmall {
    font-size: 0.8rem;
    cursor: pointer;
}


.calendarEventText {
    color: #ffffff;
    font-size: 1.2rem !important; 
}


.tableSmallLabel {
    font-size: 0.1rem;
    color: #8898aa;
}

.filterByWrapper {
    display: flex;
    flex-direction: row;
}


.negativeNumber {
    color: #d11020;
}

.dateToFormInput {
    width: 175px;
}

.dateFromFormInput {
    width: 175px;
}

.searchFilterInput {
    width: 375px;
    margin-left: 20px;
}

.btnAddPhotoDo {
    background-color: #5e72e4;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
}

.mousePointer {
    cursor: pointer;
}

.addressSelector .select2-container .select2-selection--single {
   /*  height: 180px; */
   font-size: 1rem !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 1rem !important;
   /*  height: 180px; */
   
    /* text-truncate */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-control-date-time-picker {
    font-size: 1rem !important;
}

@media screen and (max-width: 1100px) {
    .dateToForm {
        justify-content: flex-start;
    }

    .dateToFormInput {
        width: 200px;
        margin-left: 35px;
    }

    .dateFromFormInput {
        width: 200px;
        margin-left: 15px;
    }
}

@media screen and (max-width: 700px) {
    .formMinusTop {
        margin-top: 0px;
    }

    .calendarStatus {
        display: flex;
        flex-direction: column;
    }

    .filterByWrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .paymentListSort {
        margin-bottom: 40px;
    }

    .searchFilterInput {
        width: 200px;
        margin-left: 25px;
    }

    .customProgressBar {
        height: 14px !important;
    }

    .threelineLeftCorner {
        font-size: 500px;
    }

    .sidebarLogo {
        width: 3rem;
        height: auto;
    }
}